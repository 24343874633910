// extracted by mini-css-extract-plugin
export var alt = "AirportPage-module--alt--ae34c";
export var container = "AirportPage-module--container--d9167";
export var desktopOnly = "AirportPage-module--desktopOnly--f3cb0";
export var main = "AirportPage-module--main--7581d";
export var mobileOnly = "AirportPage-module--mobileOnly--652c1";
export var subTitle2 = "AirportPage-module--subTitle2--db1e2";
export var textLeft = "AirportPage-module--textLeft--4f5ff";
export var title = "AirportPage-module--title--c4baa";
export var title2 = "AirportPage-module--title2--8172f";
export var visuallyHidden = "AirportPage-module--visuallyHidden--87e7d";